html,
body {
  background-color: #FEFEFE;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body.grey {
  background-color: #F9F9F9;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

img,
picture,
video {
  display: block;
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

ul,
ol {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.rawHtml-content * {
  margin: revert;
}

.rawHtml-content ul,
.rawHtml-content ol {
  padding: revert;
  list-style: revert;
}

.related-articles-rr * {
  margin: revert;
}

.related-articles-rr ul,
.related-articles-rr ol {
  padding: revert;
  list-style: revert;
}

.my-account * {
  margin: revert;
}

.my-account ul,
.my-account ol {
  padding: revert;
  list-style: revert;
}

.rawHtml-content-no-nativo * {
  margin: revert;
}

.rawHtml-content-no-nativo ul,
.rawHtml-content-no-nativo ol {
  padding: revert;
  list-style: revert;
}

@supports selector(:has(> *)) {
  .rawHtml-content *,
  .related-articles-rr *,
  .my-account *,
  .rawHtml-content-no-nativo * {
    margin: revert;

    ul,
    ol {
      padding: revert;
      list-style: revert;
    }
  }
}

/* ThirdPartiesReset */

.paywall-selector {
  position: relative !important;
  top: 10%;
  z-index: 1000;
}

@media (min-width: 528px) {
  .paywall-selector {
    position: sticky !important;
  }
}

.paywallActive {
  filter: grayscale(0.5) blur(10px);
  z-index: -1;
  pointer-events: none;
  user-select: none;
}

.paywallFade::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 300px;
  background-image: linear-gradient(
    to top,
    #FFFFFFFF,
    #FFFFFF00 50%
  );
  z-index: -1;
}

#ta-irm-form-renderer {
  position: fixed;
  z-index: 100001;
}

.paywallFade::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 300px;
  background-image: linear-gradient(
    to top,
    #FFFFFFFF,
    #FFFFFF00 50%
  );
  z-index: -1;
}

#ta-irm-form-renderer {
  position: fixed;
  z-index: 100001;
}

@media (min-width: 1440px) {
  .subscribe-page .tp-container-inner iframe {
    max-width: none !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.tp-container-inner {
  max-width: none !important;
  width: 100% !important;
}

@media (max-width: 1440px) {
  .regwall-shadow {
    box-shadow: 0px 8px 20px 0px #0000001A;
  }
}

@media (min-width: 1440px) {
  .regwall-shadow iframe {
    box-shadow: 0px 8px 20px 0px #0000001A;
  }
}

.tp-iframe-wrapper.tp-active {
  max-width: 98vw !important;
}

.tp-modal .tp-close:focus {
  border: none !important;
}

.tp-modal .tp-close.tp-active {
  transform: scale(2) !important;
}

.tp-modal .tp-close {
  top: 10px !important;
  right: 10px !important;
  width: 15px !important;
  height: 15px !important;
  box-shadow: none !important;
}

@media (max-width: 425px) {
  .tp-iframe-wrapper.tp-active {
    max-width: unset !important;
  }

  .tp-iframe-wrapper {
    text-align: center;
    width: 100% !important;
  }
}

.tp-backdrop .tp-active {
  background: #111111;
  background-image: linear-gradient(180deg, #FFFFFFC0 0%, #FFFFFF 100%);
  opacity: 0.8;
}

.stn-video-player {
  aspect-ratio: 16 / 9;
  --stn-player-borderRadius: 0;
  --stn-player-progressBarTrackColor: #007b9d;
  --stn-player-volumeBarTrackColor: #007b9d;
  --stn-player-titleBarBackground: #eff8fa;
  --stn-player-titleBarColor: #111111;
  --stn-player-titleBarButtonsBackground: #007b9d;
  --stn-player-titleBarButtonsBackgroundHover: #007b9d;
  --stn-player-miniTitleBarButtonsBackground: #007b9d;
  --stn-player-stnEngageProgressColor: #007b9d;
}
