#teconsent {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  #teconsent {
    padding-bottom: 70px;
  }
}

button {
  color: #111111;
}

ul[class^='nativo-well-'] {
  list-style: none;
  display: block !important;
}

div.nativo-rail div.ntv-rail ul {
  row-gap: 13px !important;
  column-gap: 13px !important;
}

div.ntv-rail ul {
  list-style: none !important;
  flex-flow: row !important;
  column-gap: 10px !important;
  row-gap: 10px !important;
}

@media screen and (max-width: 1024px) {
  div.ntv-rail ul {
    flex-flow: row wrap !important;
  }
}

div.ntv-rail ul li:not(:last-of-type) {
  margin-right: 0 !important;
}

div.ntv-rail ul li:last-of-type {
  margin-right: 25px !important;
}

div.nativo-rail {
  grid-column: span 2;
}

@media screen and (min-width: 1024px) {
  div.nativo-rail {
    grid-column: span 3;
  }
}

div.nativo-rail ul {
  list-style: none !important;
}

@media screen and (max-width: 1152px) {
  div.nativo-right-tout {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .nativo-section:empty {
    display: none;
  }

  ul .nativo-section  {
    margin: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  ul:has(li.nativo-section:empty) {  
     height: calc(100vw * 0.88) !important;
  }
}

div#article-related.latest-popular-module {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

#article-related .latest-popular-content {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    gap: 40px;
}

@media (min-width: 768px) {
    div#article-related.latest-popular-module {
        gap: 48px;
    }

    #article-related .latest-popular-content {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 48px;
    }
}

#article-related .heading {
    display: flex;
    align-items: center;
}

#article-related .heading::before, #article-related .heading::after {
    content: '';
    flex-grow: 1;
    height: 0;
    background-color: #f2f2f2;
}

@media (min-width: 768px) {
    #article-related .heading::before, #article-related .heading::after {
      height: 1px;
    }

    #article-related .heading::before {
      margin-right: 56px;
    }
  
    #article-related .heading::after {
      margin-left: 56px;
    }
}

@media (min-width: 1440px) {
    #article-related .heading::before {
      margin-right: 104px;
    }
  
    #article-related .heading::after {
      margin-left: 104px;
    }
}

#article-related .latest-popular-title {
    letter-spacing: -1.5px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: #E31E23;
    text-decoration-thickness: 4px;
    font-weight: 600;
    line-height: 120%;
    font-family: '__saol_40b425', "Times New Roman", "Playfair Display", Georgia, serif;
    letter-spacing: 0px;
    font-size: 2rem;
}

#article-related .link-wrapper > a {
    font-family: '__graphikCond_cdc833';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    z-index: 2; 
    text-decoration: none;
    color: #E4121A;
}

#article-related .card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#article-related .title-link-wrapper > a {
    font-family: '__saol_40b425';
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    text-decoration: none !important;
    line-height: 28px;
    display: block;
    color: rgb(17, 17, 17);
}

#article-related .title-link-wrapper > a:hover {
    color: rgb(0, 123, 157);
    text-decoration: none;
}

#article-related .date {
    color: #666666;
    font-family: '__graphikCond_cdc833';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.17px;
    line-height: 21px;
    min-height: 21px;
    position: relative;
    z-index: 2;
}

#article-related .author-list{
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 21px;
    margin: 0px;
    text-transform: uppercase;
}

#article-related .by-text {
    font-family: '__graphikCond_cdc833';
    font-weight: inherit;
    color: #111111;
    margin: 0px 4px 0px 0px;
}

#article-related .list-item-wrapper {
    font-family: '__graphikCond_cdc833';
    font-weight: 600; 
}


/* ------ CSS VARIABLES >> ------ */
:root {
    --Section-Margin: 3rem; /*48px*/
    --Grid-Gap: 1rem; /*16px*/
    --Grid-Margin: 1.25rem; /*20px*/
    --Footer-Margin: 5rem; /*80px*/
    --Heading-Margin: 1.5rem; /*24px*/
}

/*md*/
@media (min-width: 768px) {
    :root {
        --Grid-Gap: 1.5rem; /*24px*/
        --Grid-Margin: 2rem; /*32px*/
    }
}

/*lg*/  
@media (min-width: 1024px) {
    :root {
        --Section-Margin: 4.25rem; /*68px*/
    }
}

/*xl*/  
@media (min-width: 1280px) {
    :root {
        --Grid-Gap: 2.5rem; /*40px*/
        --Grid-Margin: 3rem; /*48px*/
    }
}
/* ------ << CSS VARIABLES ------ */